
.edit-item-status-modal .select2-dropdown {
    min-height: 335px;

    .inner {
        min-height: 320px!important;
        max-height: 320px!important;
    }


}
